import { Room } from '@wonderschool/common-base-types';
import firebase from 'firebase/compat/app';
import invariant from 'ts-invariant';
import { firestore } from './firebase';
import {
  GetDocumentsAsArrayResult,
  getDocument,
  getDocumentsAsArray,
  startChunkedCollectionListener,
} from './firestore';
import { buildObject } from './helpers/firestore';

interface OrganizationRoomsListenerParams {
  organizationId: string;
  validRoomIds?: string[];
}

const paths = {
  room: (organizationId: string, roomId: string): string => `organizations/${organizationId}/rooms/${roomId}`,
  rooms: (organizationId: string): string => `organizations/${organizationId}/rooms`,
};

// lifted from https://github.com/wonderschool/ws-mobile-2/blob/main/src/api/firebase/rooms.ts#L13
export function organizationRoomsOnListen(
  { organizationId, validRoomIds }: OrganizationRoomsListenerParams,
  next: (rooms: Room[]) => void,
  error?: (error: Error) => void,
  complete?: () => void
) {
  const hasRoomIds = validRoomIds && validRoomIds.length > 0;
  const sortFn = hasRoomIds
    ? (roomA: Room, roomB: Room) => {
        return (roomA.name || '').localeCompare(roomB.name || '');
      }
    : undefined;
  return startChunkedCollectionListener(
    {
      path: paths.rooms(organizationId),
      orderBy: hasRoomIds
        ? undefined // rely on sortFn here; can't use orderBy w/ `in` queries
        : [{ field: 'name', direction: 'asc' }],
      conditionsBase: [],
      chunkableCondition: hasRoomIds
        ? {
            field: firestore.FieldPath.documentId(),
            operation: 'in',
            value: validRoomIds,
          }
        : undefined,
    },
    next,
    error,
    complete,
    sortFn
  );
}

export async function addOrganizationRoom(
  organizationId: string,
  room: Partial<Room>
): Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>> {
  const docRef = await firestore().collection(paths.rooms(organizationId)).add(room);
  return docRef;
}

export async function updateOrganizationRoom(organizationId: string, room: Partial<Room>): Promise<Room | null> {
  const { id, ...roomData } = room;
  const docRef = firestore().doc(paths.room(organizationId, id!));
  await docRef.update(roomData);
  const docSnapshot = await docRef.get();
  return buildObject<Room>(docSnapshot);
}

export async function getRoom(organizationId: string | null, roomId: string | null): Promise<Room | null> {
  invariant(organizationId, 'organizationId is required');
  invariant(roomId, 'roomId is required');

  const room = await getDocument<Room>({
    path: paths.room(organizationId, roomId),
  });

  return room;
}

/**
 * Fetches all rooms for a given organization.
 */
export async function fetchOrganizationRooms(
  organizationId: string | null,
  conditions: any[] = []
): Promise<GetDocumentsAsArrayResult<Room>> {
  invariant(organizationId, 'organizationId is required');

  const docsArray = await getDocumentsAsArray<Room>({
    path: paths.rooms(organizationId),
    conditions,
  });

  return docsArray;
}
