import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useUploadFile } from '@wonderschool/file-service-client';

import { ShowError, ShowSuccess, ShowWarning } from '../../../Components/Messages';
import DocumentUploader from '../../../Components/Upload/DocumentUploader';

import { FormComponentProps } from '../../../common';
import { WsDocumentUpload } from '../../../icons';
import { logError } from '../../../rollbar';

import { allowedMimeTypes } from '../../../documents/dictionary';
import { useOrganization } from '../../../hooks/useOrganizations';
import { DocumentType } from '../../types';

import { FileCategory, FileDocument } from '@wonderschool/common-base-types';
import { Button, Input } from '@wonderschool/common-base-ui';
import { StaffDocumentsTable as DocumentsTable } from '../../../documents';

type DocumentsFormProps = FormComponentProps<DocumentType[]> & {
  documents?: DocumentType[];
};

const DocumentsForm: React.FC<DocumentsFormProps> = ({ title, documents, isSaving, onSave }) => {
  const { t } = useTranslation();
  const organization = useOrganization();
  const { uploadFile, error: uploadError, isSuccess: uploadSuccess, reset: resetUploadHook } = useUploadFile();
  const { id: currentOrganizationId } = useOrganization();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState<DocumentType[]>(documents ?? []);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [dontSendDocuments, setDontSendDocuments] = useState(false);

  const [fileToUpload, setFileToUpload] = useState<File | null>(null);

  const validate = useCallback(() => {
    const errorsLocal: Record<string, string> = {};
    if (!dontSendDocuments && selectedDocuments.length === 0) {
      errorsLocal['documents'] = t('enrollments.documentsSelectOne');
    }
    return errorsLocal;
  }, [selectedDocuments, t, dontSendDocuments]);

  useEffect(() => {
    if (!isSaving) return;

    const errorsLocal = validate();
    if (Object.keys(errorsLocal).length > 0) {
      setErrors(errorsLocal);
      onSave?.({ errors: errorsLocal });
    } else {
      onSave?.({ data: dontSendDocuments ? [] : selectedDocuments });
    }
  }, [errors, isSaving, onSave, selectedDocuments, validate, dontSendDocuments]);

  useEffect(() => {
    if (uploadSuccess && fileToUpload) {
      setFileToUpload(null);
      resetUploadHook();
    }
  }, [fileToUpload, resetUploadHook, uploadSuccess]);

  const renderBanner = useCallback(() => {
    if (dontSendDocuments) return null;

    const bannerMessage = t('enrollments.documentsSelectOne');

    if (selectedDocuments.length > 0) {
      return <ShowSuccess content={bannerMessage} hideHeader={true} />;
    } else if (Object.keys(errors).length > 0) {
      return <ShowError content={bannerMessage} hideHeader={true} />;
    } else {
      return <ShowWarning content={bannerMessage} hideHeader={true} />;
    }
  }, [errors, selectedDocuments, t, dontSendDocuments]);

  const handleSelectedDocuments = useCallback((selectedDocuments: FileDocument[]) => {
    const selectedDocumentsLocal = selectedDocuments.map(
      (document) =>
        ({
          id: document.id,
          name: document.name,
          url: document.fileURL,
        }) as DocumentType
    );
    setSelectedDocuments(selectedDocumentsLocal);
  }, []);

  const onFileSelected = useCallback(({ data: file }: { data: File }) => {
    if (file) {
      setFileToUpload(file);
    }
  }, []);

  const onFileSubmit = useCallback(async () => {
    if (!fileToUpload) {
      return null;
    }

    try {
      // start the request
      uploadFile({
        file: fileToUpload,
        options: {
          fileAction: 'downloadable',
          fileCategory: FileCategory.FORM,
          metadata: {
            uploadPath: `/organizations/${currentOrganizationId}`,
          },
        },
      });

      // close the modal
    } catch (error) {
      // log it with rollbar
      logError('Documents - Upload error: ', {
        clientError: error,
        serverMessage: uploadError,
      });
    }

    return null;
  }, [currentOrganizationId, fileToUpload, uploadError, uploadFile]);

  return (
    <div className="space-y-4" data-testid="docs-upload-wrap">
      <div className="flex items-center justify-between" data-testid="docs-upload-title">
        <h2 className="text-2xl font-bold">
          {t(title ?? 'enrollments.documentsFormTitle', { name: organization.name })}{' '}
          <span className="text-red-900">*</span>
        </h2>
        <DocumentUploader
          onFileSelected={onFileSelected}
          onSubmit={onFileSubmit}
          onError={() => null}
          onClose={() => setIsModalOpen(false)}
          shouldUseExternalStorageApi
          title={t('Upload Files')}
          allowedFileTypes={allowedMimeTypes}
          maxNumberOfFiles={1}
          modalOverrideProps={{
            trigger: <UploadLink />,
            open: isModalOpen,
          }}
          data-testid="docs-uploader"
        />
      </div>

      <div className="flex items-center">
        <Input
          type="checkbox"
          id="dontSendDocuments"
          checked={dontSendDocuments}
          onChange={(e) => setDontSendDocuments(e.target.checked)}
          className="mr-2"
        />
        <label htmlFor="dontSendDocuments" className="text-sm">
          {t('enrollments.dontSendDocuments')}
        </label>
      </div>

      {renderBanner()}
      {!dontSendDocuments && (
        <DocumentsTable
          onRowSelect={handleSelectedDocuments}
          hideBanner={true}
          hideActions={true}
          selectedDocumentIds={selectedDocuments.map((document) => document.id)}
        />
      )}
    </div>
  );

  function UploadLink() {
    if (dontSendDocuments) return null;
    return (
      <div>
        <Button
          onClick={() => {
            setIsModalOpen(true);
          }}
          data-testid="docs-icon-link"
        >
          <WsDocumentUpload className="mr-2" />
          <span>{t('enrollments.documentUploadButton')}</span>
        </Button>
      </div>
    );
  }
};

export default DocumentsForm;
