import { ParentHandbookConfirmationModal, ParentHandbookPage } from '@wonderschool/ccms-ui';
import { Dialog } from '@wonderschool/common-base-ui';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '../hooks/useUser';
import { RouteNameEnum, useRoutes } from '../navigation';

const ParentHandbook = () => {
  const { gotoRouteByName } = useRoutes();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const user = useUser();
  const accountHolderName = user?.firstName + ' ' + user?.lastName;

  const handleRouteToDocuments = () => {
    gotoRouteByName(RouteNameEnum.DOCUMENTS);
  };

  const handleFinish = () => {
    setIsDialogOpen(false);
    setShowConfirmation(true);
  };

  return (
    <>
      <Dialog
        isOpen={isDialogOpen}
        onClose={handleRouteToDocuments}
        extraDialogWrapperClasses="fixed inset-0 z-50 flex items-center justify-center"
        fullScreenOnMobile={true}
        fullScreenOnDesktop={true}
      >
        <ParentHandbookPage
          account_holder_name={accountHolderName}
          useParams={useParams}
          routeToDocuments={handleRouteToDocuments}
          onFinish={handleFinish}
        />
      </Dialog>
      {showConfirmation && (
        <ParentHandbookConfirmationModal
          isOpen={showConfirmation}
          onClose={handleRouteToDocuments}
          onShare={handleRouteToDocuments}
        />
      )}
    </>
  );
};

export default ParentHandbook;
