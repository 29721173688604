import {
  BusinessCoachCard,
  Button,
  Card,
  CoachMessageType,
  Dialog,
  EditFormSection,
  showToast,
  ToastTypeEnum,
} from '@wonderschool/common-base-ui';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fetchProcessingFeeSettings, ProcessingFees } from '../../../api/firebase/processingFees';
import { formatCurrency } from '../../../helpers/utils';
import { useOrganization } from '../../../hooks/useOrganizations';
import { updateMoxitOrganization } from '../../../redux/actions/organizationActions';

const MONETIZATION_GO_LIVE_DATE = 1740873600000;

enum Payer {
  PARENT = 'parent',
  PROVIDER = 'provider',
}

const PayerEnum = [Payer.PARENT, Payer.PROVIDER] as const;

const ProcessingFeeSettings: React.FC = () => {
  const organization = useOrganization();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const defaultPayer = Payer.PROVIDER;
  const currentPayer = organization.monetization?.payer || defaultPayer;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedPayer, setUpdatedPayer] = useState<(typeof PayerEnum)[number]>();
  const [completeFormSubmission, setCompleteFormSubmission] = useState<(() => void) | null>(null);
  const [processingFees, setProcessingFees] = useState<ProcessingFees | null>(null);

  useEffect(() => {
    const loadProcessingFees = async () => {
      try {
        const fees = await fetchProcessingFeeSettings();
        setProcessingFees(fees);
      } catch (error) {
        console.error('Error loading processing fees:', error);
      }
    };
    loadProcessingFees();
  }, []);

  const translatedPayerOptions = useMemo(
    () =>
      PayerEnum.map((payer) => ({
        label: t(`pageSettingsFinanceSection.processingFees.${payer}`),
        value: payer,
      })),
    [t]
  );

  const renderProcessingFeeForm = () => (
    <Card>
      <EditFormSection
        dataTestId="edit-form"
        header={t('pageSettingsFinanceSection.processingFees.header')}
        inputs={[
          {
            id: 'payer',
            inputType: 'select',
            label: '',
            options: translatedPayerOptions,
          },
        ]}
        onSubmit={handleSubmit}
        values={{
          payer: translatedPayerOptions.find((option) => option.value === (updatedPayer || currentPayer)),
        }}
      />
    </Card>
  );

  const handleSubmit = (formData: { payer: { value: (typeof PayerEnum)[number] } }) => {
    const newPayer = formData.payer.value;
    setUpdatedPayer(newPayer);

    return new Promise<void>((resolve) => {
      if (currentPayer !== newPayer) {
        setIsModalOpen(true);
        setCompleteFormSubmission(() => resolve);
      } else {
        resolve();
      }
    });
  };

  const handleConfirm = async () => {
    setIsModalOpen(false);
    const updatedOrganization = {
      ...organization,
      monetization: {
        ...(organization.monetization || {}),
        payer: updatedPayer,
      },
    };

    try {
      dispatch(updateMoxitOrganization(updatedOrganization));
    } catch (error) {
      console.error('Error updating organization:', {
        organization,
        error,
      });
      showToast(ToastTypeEnum.Error, t('pageSettingsFinanceSection.processingFees.confirmChanges.error'));
    } finally {
      completeFormSubmission?.();
      setCompleteFormSubmission(null);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setUpdatedPayer(currentPayer);
    completeFormSubmission?.();
    setCompleteFormSubmission(null);
  };

  const renderConfirmDialog = () => {
    const newPayerLabel = translatedPayerOptions.find((option) => option.value === updatedPayer)?.label;
    const currentPayerLabel = translatedPayerOptions.find((option) => option.value === currentPayer)?.label;
    return (
      <Dialog isOpen={isModalOpen} onClose={closeModal} fullScreenOnMobile={true}>
        <Dialog.Panel>
          <Dialog.Title>
            <div className="flex flex-col gap-2 px-4">
              {t('pageSettingsFinanceSection.processingFees.confirmChanges.title')}
            </div>
          </Dialog.Title>
          <Dialog.Description>
            <div className="px-4 text-gray-900">
              {t('pageSettingsFinanceSection.processingFees.confirmChanges.body', {
                currentPayer: currentPayerLabel,
                newPayer: newPayerLabel,
              })}
            </div>
          </Dialog.Description>
        </Dialog.Panel>
        <Dialog.Actions>
          <div className="flex w-full gap-3 px-4 py-4">
            <Button onClick={closeModal} primary={false} extraClasses="flex-1 border border-gray-300">
              {t('pageSettingsFinanceSection.processingFees.confirmChanges.cancel')}
            </Button>
            <Button primary onClick={handleConfirm} extraClasses="flex-1">
              {t('pageSettingsFinanceSection.processingFees.confirmChanges.saveChanges')}
            </Button>
          </div>
        </Dialog.Actions>
      </Dialog>
    );
  };

  const renderProcessingFeeHelpCard = () => (
    <div className="pt-4">
      <BusinessCoachCard
        ctaText={t('pageSettingsFinanceSection.processingFees.businessCoachCard.cta')}
        messageType={CoachMessageType.INFO}
        name={t('pageSettingsFinanceSection.processingFees.businessCoachCard.header')}
        onCtaClick={() =>
          window.open(
            'https://wonderschool.zendesk.com/hc/en-us/articles/29251951033367-Payment-Processing-Fees',
            '_blank'
          )
        }
        text={getHelpCardCopy()}
      />
    </div>
  );

  const getHelpCardCopy = () => {
    const beforeLaunch = Date.now() <= MONETIZATION_GO_LIVE_DATE;
    if (beforeLaunch) {
      if (processingFees) {
        return t('pageSettingsFinanceSection.processingFees.businessCoachCard.beforeLaunch.body', {
          cardFeePercentage: processingFees.cardFeePercentage,
          cardFixedFee: formatCurrency(processingFees.cardFixedFee, { precision: 2 }),
          achFeePercentage: processingFees.achFeePercentage,
          achMaxFee: formatCurrency(processingFees.achMaxFee, { precision: 2 }),
        });
      }

      return t('pageSettingsFinanceSection.processingFees.businessCoachCard.beforeLaunch.bodyWithoutFeeData');
    }

    if (processingFees) {
      return t('pageSettingsFinanceSection.processingFees.businessCoachCard.body', {
        cardFeePercentage: processingFees.cardFeePercentage,
        cardFixedFee: formatCurrency(processingFees.cardFixedFee, { precision: 2 }),
        achFeePercentage: processingFees.achFeePercentage,
        achMaxFee: formatCurrency(processingFees.achMaxFee, { precision: 2 }),
      });
    }

    return t('pageSettingsFinanceSection.processingFees.businessCoachCard.bodyWithoutFeeData');
  };

  return (
    <>
      {renderProcessingFeeForm()}
      {renderProcessingFeeHelpCard()}
      {renderConfirmDialog()}
    </>
  );
};

export default ProcessingFeeSettings;
