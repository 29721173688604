import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Button, Card, Container, Segment } from 'semantic-ui-react';

// Import components
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { EnforceAutomaticPayment, ProcessingFeeSettings } from '../Components/Billing/Settings';
import { BusyIndicator } from '../Components/Shared/BusyIndicator';
import PageTitle from '../Components/Shared/PageTitle';
import { functions } from '../api/firebase/firebase';
import { getCreatePayoutAccountUrl } from '../api/firebase/organizations';
import { getTitlePrefix } from '../config/env';
import { useOrganization } from '../hooks/useOrganizations';
import { WsEdit, WsEmail, WsLock } from '../icons';
import { logError } from '../rollbar';
import { PAGE_NAMES, SEGMENT_EVENTS, useSegmentPage, useSegmentTrack } from '../segment';
import './Finances.scss';

const Finances = (_props) => {
  const { paymentsMonetizationV2IsProviderSettingEnabled } = useFlags();
  const { t } = useTranslation();
  const organization = useOrganization();
  const segmentTrack = useSegmentTrack();

  useSegmentPage(PAGE_NAMES.finance);

  const [isLoading, setIsLoading] = useState();
  const [isRedirecting, setIsRedirecting] = useState();
  const [payoutAccount, setPayoutAccount] = useState();

  useEffect(() => {
    const fetchPayoutAccountDetails = async () => {
      if (!organization?.stripe?.accountId) return;

      setIsLoading(true);

      const result = await functions().httpsCallable('callables-stripe-getPayoutAccountDetails')({
        organizationId: organization?.id,
      });

      setPayoutAccount(result?.data);
      setIsLoading(false);
    };

    fetchPayoutAccountDetails();
  }, [organization?.id, organization?.stripe?.accountId]);

  const handleConnectClick = async () => {
    setIsRedirecting(true);
    try {
      segmentTrack(SEGMENT_EVENTS.addPayoutAccountSettings);
      const setupPayoutUri = await getCreatePayoutAccountUrl(organization.id);
      window.location.href = setupPayoutUri;
    } catch (error) {
      setIsRedirecting(false);
      logError('Error starting Stripe connect flow', error, { error });
    }
  };

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('pageSettingsFinanceTitle')}
      </title>
      <body className="finance" />
    </Helmet>
  );

  const renderPageTitle = () => <PageTitle title={t('pageSettingsFinanceHeaderTitle')} />;

  const arePaymentsEnabled = organization?.stripe?.paymentsEnabled;

  const renderHeaderRight = () => {
    if (isLoading || isRedirecting) return <BusyIndicator />;

    if (!payoutAccount)
      return (
        <Button
          data-testid="connect-button"
          onClick={handleConnectClick}
          className={`connect-button${arePaymentsEnabled ? 'enabled' : ''}`}
          disabled={!arePaymentsEnabled}
        >
          {t('billing.settings.connectButton')}
        </Button>
      );

    if (arePaymentsEnabled)
      return (
        <Link
          to={{ pathname: 'https://connect.stripe.com/express_login' }}
          target="_blank"
          className="edit-button"
          data-testid="edit-button"
        >
          <WsEdit className="icon-right" />
          {t('common.edit')}
        </Link>
      );
  };

  const renderPaymentAccountContent = () => {
    if (isLoading) return;

    if (payoutAccount) {
      const isCard = payoutAccount.type === 'card';
      return (
        <div className="account-section">
          <div className="column" data-testid="payment-account-type">
            <b>{isCard ? t('billing.settings.card') : t('billing.settings.bank')}</b>
            {payoutAccount.name}
          </div>
          <div className="column" data-testid="payment-account-number">
            <b>{t('billing.settings.accountNumber')}</b>
            **** {payoutAccount.last4}
          </div>
        </div>
      );
    }

    return <span>{t('billing.settings.connectAccountPrompt')}</span>;
  };

  const renderPaymentsServiceSection = () => (
    <Card fluid>
      <Card.Content style={{ display: 'flex' }}>
        {arePaymentsEnabled ? null : <WsLock className="icon-right lock-icon" />}
        <Card.Header as="h3" className="header-with-icon" data-testid="payments-service-title">
          {t('billing.settings.paymentsServiceTitle')}
        </Card.Header>
        {arePaymentsEnabled ? (
          <span className="enabled-text" data-testid="payments-service-enabled">
            {t('billing.settings.enabled')}
          </span>
        ) : (
          <span className="disabled-text" data-testid="payments-service-disabled">
            {t('billing.settings.disabled')}
          </span>
        )}
      </Card.Content>
      {arePaymentsEnabled ? null : (
        <Card.Content className="content-no-border">
          <span data-testid="payments-service-prompt">{t('billing.settings.enablePaymentsPrompt')}</span>
          <div className="link-section">
            <a
              href="mailto:support@wonderschool.com"
              target="_blank"
              rel="noreferrer"
              style={{ display: 'flex' }}
              data-testid="payments-service-email"
            >
              <WsEmail className="email-icon" />
              {t('support@wonderschool.com')}
            </a>
          </div>
        </Card.Content>
      )}
    </Card>
  );

  const renderPaymentAccountSection = () => (
    <Card fluid className={`${arePaymentsEnabled ? '' : 'disabled-section'}${isLoading ? 'placeholder-padding' : ''}`}>
      <Card.Content style={{ display: 'flex' }}>
        {arePaymentsEnabled ? null : <WsLock className="icon-right lock-icon" />}
        <Card.Header as="h3" className="header-with-icon" data-testid="payment-account-title">
          {t('billing.settings.paymentAccountTitle')}
        </Card.Header>
        {renderHeaderRight()}
      </Card.Content>
      <Card.Content className="content-no-border" style={{ paddingTop: 0 }}>
        {renderPaymentAccountContent()}
      </Card.Content>
    </Card>
  );

  return (
    <Container className="no-padding no-margin">
      {renderPageHead()}

      <Segment basic>
        {renderPageTitle()}
        {renderPaymentsServiceSection()}
        {renderPaymentAccountSection()}
        <EnforceAutomaticPayment />
        {paymentsMonetizationV2IsProviderSettingEnabled ? <ProcessingFeeSettings /> : null}
      </Segment>
    </Container>
  );
};

export default Finances;
