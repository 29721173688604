import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { ProcessingFees } from '../../../api/firebase/processingFees';
import { formatCurrency, formatPercent } from '../../../helpers/utils';
import './FeeDisclaimer.scss';

type AchFeeConfiguration = {
  max_fee: number;
  percentage: number;
};

type CardFeeConfiguration = {
  percentage: number;
  fixed_fee: number;
};

export type ProcessingFeeConfigs = {
  achFees: AchFeeConfiguration;
  cardFees: CardFeeConfiguration;
};

type Props = {
  processingFees?: ProcessingFees | null;
  errorOnLoadingFees?: boolean;
};

const LoadingFeeErrorMessage = (t: TFunction<'translation', undefined>) => {
  return (
    <div className="fee-disclaimer" data-testid="fee-disclaimer-generic">
      {t('billing.invoiceDetails.processingFeeDisclaimerGeneric')}
    </div>
  );
};

const FeeDisclaimer: React.FC<Props> = ({ processingFees, errorOnLoadingFees }) => {
  const { t } = useTranslation();

  if (errorOnLoadingFees) return LoadingFeeErrorMessage(t);

  if (!processingFees) return null;

  const { cardFeePercentage, cardFixedFee, achFeePercentage, achMaxFee } = processingFees;

  return (
    <div className="fee-disclaimer" data-testid="fee-disclaimer-text">
      {t('billing.invoiceDetails.processingFeeDisclaimer', {
        card_percentage_fee: formatPercent(cardFeePercentage, 2),
        card_fixed_fee: formatCurrency(cardFixedFee),
        ach_percentage_fee: formatPercent(achFeePercentage, 2),
        ach_max_fee: formatCurrency(achMaxFee),
      })}
    </div>
  );
};

export default FeeDisclaimer;
