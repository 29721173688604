import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { getTitlePrefix } from '../config/env';
import { ParentMain, StaffMain } from '../documents';

import { MainContentLayout } from '@wonderschool/common-base-ui';
import { useUser } from '../hooks/useUser';

const FormsAndDocuments: React.FC = () => {
  const { t } = useTranslation();
  const { isParent } = useUser();

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: t('Documents') })}
      </title>
      <body />
    </Helmet>
  );

  return (
    <MainContentLayout title={t('Documents')}>
      {renderPageHead()}
      {isParent ? <ParentMain /> : <StaffMain />}
    </MainContentLayout>
  );
};

export default FormsAndDocuments;
