export const TRANSACTIONS_FETCHED = 'TRANSACTIONS_FETCHED';
export const TRANSACTION_SAVED = 'TRANSACTION_SAVED';
export const TRANSACTION_CATEGORIES_FETCHED = 'TRANSACTION_CATEGORIES_FETCHED';
export const TRANSACTION_SEARCH_CONDITIONS_CHANGED = 'TRANSACTION_SEARCH_CONDITIONS_CHANGED';

export const transactionSavedAction = (transaction, transactionAdded) => ({
  type: TRANSACTION_SAVED,
  transaction: transaction,
  transactionAdded: transactionAdded,
});

export const transactionsFetchedAction = (transactions) => ({
  type: TRANSACTIONS_FETCHED,
  transactions: transactions,
});

export const transactionCategoriesFetchedAction = (categories) => ({
  type: TRANSACTION_CATEGORIES_FETCHED,
  categories: categories,
});

export type TransactionsReducerState = {
  categories: any | null;
  searchConditions: any | null;
  currentTransaction: any | null;
  transactionAdded: boolean;
  count: number;
  list: any | null;
  lastDocument: any | null;
};

const initialState: TransactionsReducerState = {
  categories: null,
  searchConditions: null,
  currentTransaction: null,
  transactionAdded: false,
  count: 0,
  list: null,
  lastDocument: null,
};
export const transactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TRANSACTION_CATEGORIES_FETCHED:
      return {
        ...state,
        categories: action.categories,
      };

    case TRANSACTION_SEARCH_CONDITIONS_CHANGED:
      break;

    case TRANSACTIONS_FETCHED:
      return {
        ...state,
        count: action.transactions.count,
        list: action.transactions.list,
        lastDocument: action.transactions.lastDocument,
      };

    case TRANSACTION_SAVED:
      if (action.transactionAdded) {
        return {
          ...state,
          currentTransaction: action.transaction,
          transactionAdded: action.transactionAdded,
        };
      } else {
        return {
          ...state,
          currentTransaction: action.transaction,
          transactionAdded: action.transactionAdded,
        };
      }
    default:
      return state;
  }
};
