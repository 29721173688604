import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import { ProcessingFees, fetchProcessingFeeSettings } from '../../../api/firebase/processingFees';
import FeeDisclaimer from './FeeDisclaimer';

const FeeDisclaimerContainer = () => {
  const { isMonetizationV2Enabled } = useFlags();
  const [processingFees, setProcessingFees] = useState<ProcessingFees | null>(null);
  const [errorOnLoadingFees, setErrorOnLoadingFees] = useState<boolean>(false);

  useEffect(() => {
    const loadProcessingFees = async () => {
      try {
        const fees = await fetchProcessingFeeSettings();
        setProcessingFees(fees);
        setErrorOnLoadingFees(false);
      } catch (error) {
        setErrorOnLoadingFees(true);
      }
    };
    loadProcessingFees();
  }, []);

  if (isMonetizationV2Enabled) {
    return <FeeDisclaimer processingFees={processingFees} errorOnLoadingFees={errorOnLoadingFees} />;
  } else {
    return null;
  }
};

export default FeeDisclaimerContainer;
