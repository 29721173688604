import { BillingFeaturesEnum, OrganizationType } from '@wonderschool/common-base-types';

export function getBillingFeatureFromOrganization(name: BillingFeaturesEnum, organization: OrganizationType) {
  if (!name || !organization?.billingFeatures) return null;
  return organization.billingFeatures.find((feature) => feature.name === name);
}

export function isBillingFeatureEnabled(organization: OrganizationType, billingFeatureName: BillingFeaturesEnum) {
  return organization?.billingFeatures?.some(({ name, enabled }) => name === billingFeatureName && enabled) ?? false;
}
