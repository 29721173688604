import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { RoomsList } from '../Components/Rooms/RoomsList';
import { getTitlePrefix } from '../config/env';
import { PAGE_NAMES, useSegmentPage } from '../segment';

export function Rooms() {
  const { t } = useTranslation();
  useSegmentPage(PAGE_NAMES.rooms);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: t('Rooms') })}
      </title>
      <body className="rooms" />
    </Helmet>
  );

  return (
    <>
      {renderPageHead()}
      <RoomsList />
    </>
  );
}

export default Rooms;
