// TODO: Lodash should no longer be used here
// eslint-disable-next-line no-restricted-imports
import { defaultsDeep, find, isEmpty, omit } from 'lodash';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line no-restricted-imports
import {
  Button,
  Checkbox,
  CheckboxProps,
  Form,
  Icon,
  InputOnChangeData,
  Modal,
  Popup,
  Segment,
  Select,
} from 'semantic-ui-react';

// Import components
import LocationPicker from '../Locations/LocationPicker';
import InlineError from '../Messages/InlineError';
import ShowError from '../Messages/ShowError';

// Import actions
import { OrganizationType, Room } from '@wonderschool/common-base-types';
import { ageRangeMonths, ageRangeYears } from '../../config';
import { useOrganization } from '../../hooks/useOrganizations';
import { useUser } from '../../hooks/useUser';
import {
  organizationAddRoom,
  organizationUpdateRoom,
  roomAdded,
  roomSelectionCleared,
  roomUpdated,
} from '../../redux/actions/roomActions';
import { RootReducerState } from '../../redux/reducers/rootReducer';
import { useStudents } from '../../students/studentsHooks';

type Errors = {
  [K in keyof Omit<Room, 'id' | 'createdBy' | 'createdAt'>]?: string;
};

export const INITIAL_ROOM: Room = {
  id: '',
  name: '',
  location: '',
  enabledActivities: [],
  ratio: '',
  ageRange: {
    from: {
      months: ageRangeMonths[0].value,
      years: ageRangeYears[0].value,
    },
    to: {
      months: ageRangeMonths[0].value,
      years: ageRangeYears[0].value,
    },
  },
  enabled: true,
  usesExactAgeRange: false,
  createdBy: undefined,
  createdAt: '',
  organization: '',
};

export interface ReduxStateProps {
  user: {
    uid: string;
    email?: string;
  };
  currentOrganization: {
    id: string;
  };
  rooms: {
    selectedRoom: {
      id: string;
    };
    list: {
      id: string;
    }[];
  };
  students: {
    selectedStudent: {
      id: string;
    };
    list: {
      id: string;
      rooms: string[];
    }[];
  };
}

type RoomFormProps = {
  onSubmit: (data: Room) => void;
  onCancel: () => void;
};

export function RoomForm({ onSubmit, onCancel }: RoomFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Replace mapStateToProps with useSelector
  const user = useUser();
  const currentOrganization: OrganizationType = useOrganization();
  const rooms = useSelector((state: RootReducerState) => state.rooms);
  const students = useStudents();

  // Convert state to useState hooks
  const [data, setData] = useState<Room>(INITIAL_ROOM);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>({});
  const [disableModalOpen, setDisableModalOpen] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(roomSelectionCleared());
    };
  }, [dispatch]);

  // Replace getDerivedStateFromProps
  useEffect(() => {
    if (!isEmpty(rooms.selectedRoom?.id) && data.id !== rooms.selectedRoom?.id) {
      setData(ensureCorrectRoomValues(rooms.selectedRoom));
    }
  }, [rooms.selectedRoom, data.id]);

  // Convert class methods to function declarations
  const onChange = (e: ChangeEvent<HTMLInputElement>, { name, value }: InputOnChangeData) => {
    setData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => omit(prev, name));
  };

  const onDateRangeChange = (e: ChangeEvent<HTMLInputElement>, { name, value }: InputOnChangeData) => {
    const keys = name.split('-');
    setData((prev) => ({
      ...prev,
      ageRange: {
        from: {
          ...(prev.ageRange?.from ?? { months: 0, years: 0 }),
          [keys[1]]: value,
        },
        to: {
          ...(prev.ageRange?.to ?? { months: 0, years: 0 }),
          [keys[1]]: value,
        },
      },
    }));
    setErrors((prev) => omit(prev, 'ageRange'));
  };

  const tryToggleRoomEnabled = (e: FormEvent<HTMLInputElement>, { name, checked }: CheckboxProps) => {
    if (checked === false) {
      const roomHasStudent = students.list?.find((student) => {
        return student.rooms.find((roomId) => roomId === data.id);
      });

      if (roomHasStudent) {
        setDisableModalOpen(true);
        return;
      }
    }

    setData((prev) => ({
      ...prev,
      enabled: checked ?? false,
    }));
    setErrors((prev) => (name ? omit(prev, name) : prev));
  };

  const forceDisableRoom = () => {
    setData((prev) => ({
      ...prev,
      enabled: false,
    }));
    setErrors((prev) => omit(prev, 'enabled'));
    setDisableModalOpen(false);
  };

  const validate = (data) => {
    const errors: Errors = {};

    const { value: fromYears } = find(ageRangeYears, (o) => o.value === data.ageRange?.from?.years) ?? {
      value: INITIAL_ROOM.ageRange?.from?.years,
    };
    const { value: fromMonths } = find(ageRangeMonths, (o) => o.value === data.ageRange?.from?.months) ?? {
      value: INITIAL_ROOM.ageRange?.from?.months,
    };
    const { value: toYears } = find(ageRangeYears, (o) => o.value === data.ageRange?.to?.years) ?? {
      value: INITIAL_ROOM.ageRange?.to?.years,
    };
    const { value: toMonths } = find(ageRangeMonths, (o) => o.value === data.ageRange?.to?.months) ?? {
      value: INITIAL_ROOM.ageRange?.to?.months,
    };

    if (!data.name) errors.name = t('Room name is required');
    if (!data.location) errors.location = t('Location is required');
    if (data.ratio && data.ratio < 1)
      errors.ratio = t('The student ratio for one teacher can not be less than one student.');

    if (fromYears && toYears) {
      if (fromYears > toYears) {
        errors.ageRange = t('The age range for this room is not set correctly.');
      } else if (fromYears === toYears && fromMonths && toMonths && fromMonths > toMonths) {
        errors.ageRange = t('The age range for this room is not set correctly.');
      }
    }

    return errors;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validationErrors = validate(data);
    setErrors(validationErrors);

    if (isEmpty(validationErrors)) {
      const { id, ...rest } = data;
      setLoading(true);

      try {
        if (id) {
          await dispatch(
            organizationUpdateRoom(currentOrganization.id, {
              id,
              ...rest,
              organization: currentOrganization.id,
              updatedAt: new Date().toISOString(),
            })
          );
          dispatch(roomUpdated(data));
        } else {
          await organizationAddRoom(currentOrganization.id, {
            ...rest,
            organization: currentOrganization.id,
            createdBy: {
              email: user?.email || '',
              uid: user?.uid || '',
            },
            createdAt: new Date().toISOString(),
          });

          dispatch(roomAdded(data));
        }
      } catch (error: unknown) {
        const message = error instanceof Error ? error.message : 'An unknown error occurred';
        setErrors({ name: message });
      } finally {
        setLoading(false);
        onSubmit(data);
      }
    }
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(roomSelectionCleared());
    onCancel();
  };

  const translatedAgeMonthOptions = ageRangeMonths.map((x) => {
    x.text = t(x.text);
    return x;
  });
  const translatedAgeYearOptions = ageRangeYears.map((x) => {
    x.text = t(x.text);
    return x;
  });

  return (
    <Segment basic textAlign="left">
      <Form id="room-form" onSubmit={handleSubmit} loading={loading} noValidate data-testid="room-form">
        <ShowError errors={errors} />
        <Form.Field error={!!errors.name} data-testid="room-field">
          <Form.Input
            required
            type="text"
            id="name"
            name="name"
            label={t('common.name')}
            placeholder={t('Room name')}
            value={data.name}
            onChange={onChange}
            data-testid="room-name"
          />
          {errors.name && <InlineError text={errors.name} data-testid="name-error" />}
        </Form.Field>

        <Form.Field error={!!errors.location} data-testid="location-field">
          <Form.Field
            required
            id="location"
            name="location"
            label={t('Location')}
            control={LocationPicker}
            placeholder={t('Select location')}
            value={data.location}
            selection
            search
            onChange={onChange}
            data-testid="room-location"
          />
          {errors.location && <InlineError text={errors.location} data-testid="location-error" />}
        </Form.Field>

        <Form.Field error={!!errors.ratio} data-testid="ratio-field">
          <Form.Input
            type="text"
            id="ratio"
            name="ratio"
            label={t('Student ratio for one teacher')}
            placeholder={t('Example: 4')}
            value={data.ratio}
            onChange={onChange}
            data-testid="student-ratio"
          />
          {errors.ratio && <InlineError text={errors.ratio} data-testid="ratio-error" />}
        </Form.Field>

        <Form.Field
          label={t('This class enrolls students at least age')}
          error={!!errors.ageRange}
          control={() => (
            <>
              <div className="room-age-input-split">
                <Form.Field
                  name="from-years"
                  className="age-input"
                  clearable
                  value={data.ageRange?.from?.years || translatedAgeYearOptions[0].value}
                  options={translatedAgeYearOptions}
                  control={Select}
                  onChange={onDateRangeChange}
                  error={!!errors.ageRange}
                  data-testid="room-age-from-years"
                />

                <Form.Field
                  name="from-months"
                  className="age-input"
                  clearable
                  value={data.ageRange?.from?.months || translatedAgeMonthOptions[0].value}
                  options={translatedAgeMonthOptions}
                  control={Select}
                  onChange={onDateRangeChange}
                  error={!!errors.ageRange}
                  data-testid="room-age-from-months"
                />
              </div>
            </>
          )}
          data-testid="age-field-least"
        />
        <Form.Field
          label={t('And no older than')}
          error={!!errors.ageRange}
          control={() => (
            <>
              <div className="room-age-input-split">
                <Form.Field
                  name="to-years"
                  className="age-input"
                  clearable
                  value={data.ageRange?.to?.years || translatedAgeYearOptions[0].value}
                  options={translatedAgeYearOptions}
                  control={Select}
                  onChange={onDateRangeChange}
                  error={!!errors.ageRange}
                  data-testid="room-age-to-years"
                />

                <Form.Field
                  name="to-months"
                  className="age-input"
                  value={data.ageRange?.to?.months || translatedAgeMonthOptions[0].value}
                  options={translatedAgeMonthOptions}
                  control={Select}
                  onChange={onDateRangeChange}
                  error={!!errors.ageRange}
                  data-testid="room-age-to-months"
                />
              </div>
              {errors.ageRange && <InlineError text={errors.ageRange} data-testid="age-range-error" />}
            </>
          )}
          data-testid="age-field-older"
        />

        {
          // @TODO: Replace the condition with a feature flag
          false && (
            <Form.Field error={!!errors.enabled} data-testid="room-form-error-message">
              <label>
                {t('Room enabled?')}
                <Popup
                  content={t(
                    "Rooms are enabled as default. This is a flag for you to keep track of your rooms. Disabling it won't impact anything."
                  )}
                  position="right center"
                  offset={[4, 0]}
                  trigger={<Icon name="info circle" />}
                />
              </label>
              <Form.Radio
                toggle
                id="enabled"
                name="enabled"
                onChange={tryToggleRoomEnabled}
                control={Checkbox}
                checked={data.enabled}
                data-testid="toggle-room-enabled"
              />
              {errors.enabled && <InlineError text={errors.enabled ?? ''} data-testid="room-field-enabled" />}
            </Form.Field>
          )
        }

        <Modal open={disableModalOpen} data-testid="disable-room-modal">
          <Modal.Header>{t('Are you sure?')}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              {t('There are enrolled students in this room. Are you sure you want to disable this room?')}
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            {/* eslint-disable-next-line i18next/no-literal-string */}
            <Button color="red" onClick={() => setDisableModalOpen(false)} data-testid="no-btn">
              No
            </Button>
            <Button
              content="Yes"
              labelPosition="right"
              icon="checkmark"
              onClick={forceDisableRoom}
              positive
              data-testid="yes-btn"
            />
          </Modal.Actions>
        </Modal>
        <p>&nbsp;</p>

        <Form.Group>
          <Form.Button primary content={data.id ? t('common.update') : t('Save')} data-testid="update-btn" />
          <Form.Button basic content={t('common.cancel')} onClick={handleCancel} data-testid="cancel-btn" />
        </Form.Group>
      </Form>
    </Segment>
  );
}

export function ensureCorrectRoomValues(roomData, defaultRoom = INITIAL_ROOM) {
  // remove empty string from ageRange.to/from so that we can use a default instead
  if (roomData?.ageRange?.to === '') {
    roomData.ageRange.to = undefined;
  }
  if (roomData?.ageRange?.from === '') {
    roomData.ageRange.from = undefined;
  }
  return {
    ...defaultsDeep({}, { ...roomData }, { ...defaultRoom }),
  };
}

export default RoomForm;
