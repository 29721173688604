import { FileCategory, FileDocument } from '@wonderschool/common-base-types';
import { AlertIcon, Button, Dialog, InformationalBanner, Select } from '@wonderschool/common-base-ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type AssignFileCategoryModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  fileToAssignCategory: FileDocument;
  handleAssignCategory: (selectedCategory: FileCategory) => void;
  documents: FileDocument[];
};

/**
 * Modal to assign a category to a file
 * @param props
 */
const AssignFileCategoryModal = ({
  isModalOpen,
  setIsModalOpen,
  fileToAssignCategory,
  handleAssignCategory,
  documents,
}: AssignFileCategoryModalProps) => {
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState<FileCategory | null>(null);

  const fileCategoryOptions = [
    { label: t('documents.staff.parentHandbook'), value: 'parent-handbook' },
    { label: t('common.none'), value: 'form' },
  ];

  const currentParentHandbooks = documents.filter((doc) => doc.fileCategory === 'parent-handbook');
  const isCurrentParentHandbook = currentParentHandbooks.some((doc) => doc.id === fileToAssignCategory.id);

  return (
    <Dialog isOpen={isModalOpen} hasCloseButton onClose={() => setIsModalOpen(false)} fullScreenOnMobile>
      <Dialog.Title>
        <h1 className="px-6 py-4">{t('documents.staff.assignCategory')}</h1>
      </Dialog.Title>
      <Dialog.Panel>
        {currentParentHandbooks.length > 0 && !isCurrentParentHandbook && (
          <InformationalBanner
            type="warning"
            label={t('documents.staff.parentHandbookWarning')}
            extraClasses="mb-4 text-sm"
            icon={<AlertIcon className="mr-2 size-6 flex-none" />}
          />
        )}
        <Select
          options={fileCategoryOptions}
          placeholder={t('documents.staff.selectCategory')}
          onChange={(e) => setSelectedCategory(e.value as FileCategory)}
          defaultValue={fileCategoryOptions.find((option) => option.value === fileToAssignCategory?.fileCategory)}
        />
      </Dialog.Panel>
      <Dialog.Actions>
        <div className="flex flex-col gap-2 md:flex-row">
          <Button label={t('Save')} primary onClick={() => handleAssignCategory(selectedCategory as FileCategory)} />
          <Button label={t('common.cancel')} onClick={() => setIsModalOpen(false)} />
        </div>
      </Dialog.Actions>
    </Dialog>
  );
};

export default AssignFileCategoryModal;
