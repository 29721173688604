import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Checkbox, DropdownItemProps } from 'semantic-ui-react';

import { Dropdown } from '../../../Components/Shared/Dropdown';

import { ShowError } from '../../../Components/Messages';
import { FormComponentProps } from '../../../common';
import { capitalize } from '../../../helpers/utils';
import { useDefaultBusinessHours } from '../../enrollmentsHooks';
import { formatHourForDisplay } from '../../enrollmentsUtils';
import { DayEnum, HourEnum, WEEKDAYS } from '../../enums';
import { BusinessHoursType, ScheduledDayType } from '../../types';

type ScheduleFormProps = FormComponentProps<ScheduledDayType[]> & {
  scheduledDays: ScheduledDayType[];
};

const ScheduleForm: React.FC<ScheduleFormProps> = ({ title, scheduledDays, isSaving, onSave }) => {
  const { t } = useTranslation();
  const defaultBusinessHours = useDefaultBusinessHours();
  const [allDays, setAllDays] = useState<ScheduledDayType[]>(initializeAllDays(scheduledDays, defaultBusinessHours));
  const [selectedDays, setSelectedDays] = useState<Record<DayEnum, ScheduledDayType | undefined>>(
    initializeSelectedDays(scheduledDays)
  );
  const [errors, setErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    if (!scheduledDays) return;
    setAllDays(initializeAllDays(scheduledDays, defaultBusinessHours));
    setSelectedDays(initializeSelectedDays(scheduledDays));
  }, [defaultBusinessHours, scheduledDays]);

  useEffect(() => {
    if (isSaving && onSave) {
      const noSelection = Object.values(selectedDays).every((val) => val === undefined);
      if (noSelection) {
        const localErrors = { noScheduleSelected: t('enrollments.scheduleSelectOne') };
        setErrors(localErrors);
        onSave?.({ errors: localErrors });
      } else {
        const saveData = Object.values(selectedDays).filter((value) => !!value);
        onSave?.({ data: saveData as ScheduledDayType[] });
      }
    }
  }, [isSaving, onSave, selectedDays, t]);

  const hoursOptions = useMemo<DropdownItemProps[]>(() => {
    return Object.values(HourEnum).map((hour) => ({
      key: hour,
      value: hour,
      text: formatHourForDisplay(hour),
    }));
  }, []);

  const createScheduledDay = useCallback((day: DayEnum, startTime: HourEnum, endTime: HourEnum) => {
    const newScheduledDay: ScheduledDayType = {
      day,
      startTime,
      endTime,
    };
    setAllDays((prev) => [...prev, newScheduledDay]);
    setSelectedDays((prev) => ({ ...prev, [day]: newScheduledDay }));
  }, []);

  const createOrUpdateScheduledDay = useCallback(
    ({
      day,
      startTime = undefined,
      endTime = undefined,
    }: {
      day: DayEnum;
      startTime: HourEnum | undefined;
      endTime: HourEnum | undefined;
    }) => {
      const scheduledDay = allDays.find((sd) => sd.day === day);

      if (scheduledDay) {
        if (startTime) scheduledDay.startTime = startTime;
        if (endTime) scheduledDay.endTime = endTime;
        setSelectedDays((prev) => ({ ...prev, [day]: scheduledDay }));
      } else {
        const _startTime = startTime ?? defaultBusinessHours?.startTime ?? HourEnum.HOUR_08_00;
        const _endTime = endTime ?? defaultBusinessHours?.endTime ?? HourEnum.HOUR_17_00;
        createScheduledDay(day, _startTime, _endTime);
      }
    },
    [createScheduledDay, defaultBusinessHours?.endTime, defaultBusinessHours?.startTime, allDays]
  );

  // only remove it from the map, not from the array, so if the user reselects the day, the previous values are still there
  const removeScheduledDays = useCallback((days: DayEnum[]) => {
    setSelectedDays((prev) => {
      const newMap = { ...prev };
      for (const day of days) newMap[day] = undefined;
      return newMap;
    });
  }, []);

  const onSelectStartTime = useCallback(
    (day: DayEnum, startTime: HourEnum) => {
      createOrUpdateScheduledDay({ day, startTime, endTime: undefined });
    },
    [createOrUpdateScheduledDay]
  );

  const onSelectEndTime = useCallback(
    (day: DayEnum, endTime: HourEnum) => {
      createOrUpdateScheduledDay({ day, startTime: undefined, endTime });
    },
    [createOrUpdateScheduledDay]
  );

  const renderScheduleItem = useCallback(
    (day: DayEnum) => {
      const scheduledDay = selectedDays[day];
      return (
        <div className="schedule-item">
          <div className="schedule-day">
            <DayCheckbox day={day} scheduledDay={scheduledDay} data-testid="select-scedule-day" />
          </div>
          <div className="schedule-time">
            <Dropdown
              basic
              fluid={false}
              value={scheduledDay?.startTime ?? ''}
              placeholder={t('enrollments.scheduleFormStartTimeLabel')}
              label={t('enrollments.scheduleFormStartTimeLabel')}
              name={'startTime'}
              onChange={(e: any, { value }: any) => {
                onSelectStartTime(day, value);
              }}
              options={hoursOptions}
              translator={null}
              isForm={false}
              data-testid="schedule-start-time"
            />
          </div>
          <div className="schedule-time">
            <Dropdown
              basic
              fluid={false}
              value={scheduledDay?.endTime ?? ''}
              placeholder={t('enrollments.scheduleFormEndTimeLabel')}
              label={t('enrollments.scheduleFormEndTimeLabel')}
              name={'endTime'}
              onChange={(e: any, { value }: any) => {
                onSelectEndTime(day, value);
              }}
              options={hoursOptions}
              translator={null}
              isForm={false}
              data-testid="schedule-end-time"
            />
          </div>
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultBusinessHours, selectedDays]
  );

  return (
    <div className="schedule-form ws-form" data-testid="schedule-form">
      <div className="ws-form-title">
        <h2 data-testid="schedule-title" className="text-2xl font-bold">
          {t(title ?? 'enrollments.scheduleFormTitle')} <span className="text-red-900">*</span>
        </h2>
        <div className="schedule-checkbox">
          <WeekdaysCheckbox />
        </div>
      </div>
      <div className="mb-4">
        <ShowError errors={errors} hideHeader />
      </div>

      <div className="schedule-items" style={{ clear: 'both' }} data-testid="schedule-items">
        {renderScheduleItem(DayEnum.MONDAY)}
        {renderScheduleItem(DayEnum.TUESDAY)}
        {renderScheduleItem(DayEnum.WEDNESDAY)}
        {renderScheduleItem(DayEnum.THURSDAY)}
        {renderScheduleItem(DayEnum.FRIDAY)}
        {renderScheduleItem(DayEnum.SATURDAY)}
        {renderScheduleItem(DayEnum.SUNDAY)}
      </div>
    </div>
  );
  type WeekdaysCheckboxProps = {
    numberOfDays: number;
    numberOfWeekdays: number;
  };

  function WeekdaysCheckbox() {
    const weekdayCheckboxProps = useMemo<WeekdaysCheckboxProps>(() => {
      return allDays.reduce(
        (acc: WeekdaysCheckboxProps, scheduledDay: ScheduledDayType) => {
          const selectedDay = selectedDays[scheduledDay.day];
          if (selectedDay) {
            acc.numberOfDays++;
            if (WEEKDAYS.includes(scheduledDay.day)) acc.numberOfWeekdays++;
          }
          return acc;
        },
        {
          numberOfDays: 0,
          numberOfWeekdays: 0,
        } as unknown as WeekdaysCheckboxProps
      );
    }, []);

    const isChecked = useMemo<boolean>(() => {
      return weekdayCheckboxProps.numberOfDays === 5 && weekdayCheckboxProps.numberOfWeekdays === 5;
    }, [weekdayCheckboxProps]);

    const isIndeterminant = useMemo<boolean>(() => {
      return !isChecked && weekdayCheckboxProps.numberOfDays > 0;
    }, [isChecked, weekdayCheckboxProps.numberOfDays]);

    return (
      <Checkbox
        label={t('enrollments.scheduleFormWeekdayLabel')}
        checked={isChecked}
        indeterminate={isIndeterminant}
        onChange={(e, { checked }) => {
          if (checked) {
            const weekdays = WEEKDAYS.map((day) => createNewDay(day, defaultBusinessHours));
            // spread pre AFTER the new ones, so the new ones won't be overwritten
            //setAllDays((prev) => [...weekdays, ...prev]);
            setSelectedDays({
              [DayEnum.MONDAY]: weekdays[0],
              [DayEnum.TUESDAY]: weekdays[1],
              [DayEnum.WEDNESDAY]: weekdays[2],
              [DayEnum.THURSDAY]: weekdays[3],
              [DayEnum.FRIDAY]: weekdays[4],
              [DayEnum.SATURDAY]: undefined,
              [DayEnum.SUNDAY]: undefined,
            });
          } else {
            removeScheduledDays(WEEKDAYS);
          }
        }}
        data-testid={`schedule-onlyweekdays-check-${isChecked}`}
      />
    );
  }

  type DayCheckboxProps = {
    day: DayEnum;
    scheduledDay: ScheduledDayType | undefined;
  };

  function DayCheckbox({ day, scheduledDay }: DayCheckboxProps) {
    return (
      <Checkbox
        checked={!!scheduledDay}
        label={t(capitalize(day))}
        onChange={(e, { checked }) => {
          setErrors({});
          if (checked) {
            const existingScheduledDay = allDays.find((sd) => sd.day === day);

            if (existingScheduledDay) {
              setSelectedDays((prev) => ({
                ...prev,
                [day]: existingScheduledDay,
              }));
            } else {
              createOrUpdateScheduledDay({ day, startTime: scheduledDay?.startTime, endTime: scheduledDay?.endTime });
            }
          } else {
            removeScheduledDays([day]);
          }
        }}
        data-testid={`select-${day}`}
      />
    );
  }
};
function initializeAllDays(
  scheduledDays: ScheduledDayType[],
  defaultBusinessHours: BusinessHoursType
): ScheduledDayType[] {
  const allDays: ScheduledDayType[] = Object.values(DayEnum).map(
    (day) => scheduledDays.find((sd) => sd.day === day) ?? createNewDay(day, defaultBusinessHours)
  );
  return allDays;
}

function initializeSelectedDays(scheduledDays: ScheduledDayType[]): Record<DayEnum, ScheduledDayType | undefined> {
  const daysMap: Record<DayEnum, ScheduledDayType | undefined> = {
    [DayEnum.MONDAY]: scheduledDays.find((sd) => sd.day === DayEnum.MONDAY),
    [DayEnum.TUESDAY]: scheduledDays.find((sd) => sd.day === DayEnum.TUESDAY),
    [DayEnum.WEDNESDAY]: scheduledDays.find((sd) => sd.day === DayEnum.WEDNESDAY),
    [DayEnum.THURSDAY]: scheduledDays.find((sd) => sd.day === DayEnum.THURSDAY),
    [DayEnum.FRIDAY]: scheduledDays.find((sd) => sd.day === DayEnum.FRIDAY),
    [DayEnum.SATURDAY]: scheduledDays.find((sd) => sd.day === DayEnum.SATURDAY),
    [DayEnum.SUNDAY]: scheduledDays.find((sd) => sd.day === DayEnum.SUNDAY),
  };
  return daysMap;
}
function createNewDay(day: DayEnum, defaultBusinessHours: BusinessHoursType): ScheduledDayType {
  return {
    day,
    startTime: defaultBusinessHours.startTime,
    endTime: defaultBusinessHours.endTime ?? HourEnum.HOUR_17_00,
  };
}
export default ScheduleForm;
