import { FoodProgramTierEnum } from '@wonderschool/common-base-types';
import { Button, Input, Option, Select, showToast, ToastTypeEnum } from '@wonderschool/common-base-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { firestore } from '../../api/firebase/firebase';
import { useOrganization } from '../../hooks/useOrganizations';
import { useAuthUser } from '../../hooks/useUser';
import { organizationUpdateStudent } from '../studentsRedux';

type StudentProgramsFormProps = {
  student: any;
  onClose: () => void;
  showPrograms?: 'foodProgram' | 'kinderConnect';
};

type ProgramsFormDataType = {
  id?: string;
  foodProgramTierObj?: Option;
  kinderConnectId?: number;
  subsidy?: Option;
};

const foodProgramTierOptions = [
  { label: 'food.tierDropdown.NotApplicable', value: '' },
  { label: 'food.tierDropdown.NoTier', value: FoodProgramTierEnum.TIER_0.toString() },
  { label: 'food.tierDropdown.Tier1', value: FoodProgramTierEnum.TIER_1.toString() },
  { label: 'food.tierDropdown.Tier2', value: FoodProgramTierEnum.TIER_2.toString() },
];

const SUBSIDY_OPTIONS = [
  { labelTranslationKey: 'common.yes', value: 'yes' },
  { labelTranslationKey: 'common.no', value: 'no' },
];

export default function StudentProgramsForm({ student, onClose, showPrograms }: StudentProgramsFormProps) {
  const { t } = useTranslation();
  const currentOrganization = useOrganization();
  const { currentUser } = useAuthUser();
  const { foodProgramFlag } = useFlags();
  const dispatch = useDispatch();

  const subsidyOptions = SUBSIDY_OPTIONS.map((option) => ({
    label: t(option.labelTranslationKey),
    value: option.value,
  }));

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<ProgramsFormDataType>();

  useEffect(() => {
    if (!student) return;
    const foundOption = foodProgramTierOptions.find((option) => option.value === student.foodProgramTier?.toString());
    const subsidyOption = subsidyOptions.find(
      (option) =>
        option.value === (student?.enrollment?.tuitionAndFees?.tuition?.detailedTuition?.subsidized ? 'yes' : 'no')
    );

    setValue('id', student.id);
    setValue(
      'foodProgramTierObj',
      foundOption
        ? { ...foundOption, label: t(foundOption.label) }
        : { ...foodProgramTierOptions[0], label: t('food.tierDropdown.NotApplicable') }
    );
    setValue('kinderConnectId', student?.kinderSystems?.kinderConnectId);
    setValue('subsidy', subsidyOption);
  }, [student, t, setValue, subsidyOptions]);

  const onSubmit = async (data: ProgramsFormDataType) => {
    const { id, foodProgramTierObj, kinderConnectId } = data;

    const foodProgramTier = foodProgramTierObj?.value ? Number(foodProgramTierObj?.value) : null;
    if (currentOrganization && currentOrganization.id) {
      if (id) {
        try {
          const updateData: any = {
            id,
            combinedFamily: null,
            organization: currentOrganization.id,
          };

          if (!showPrograms || showPrograms === 'foodProgram') {
            updateData.foodProgramTier = foodProgramTier;
          }

          if (!showPrograms || showPrograms === 'kinderConnect') {
            updateData.kinderSystems = {
              kinderConnectId: Number(kinderConnectId),
              updatedBy: currentUser?.displayName,
              updatedAt: firestore.FieldValue.serverTimestamp(),
            };
          }

          updateData.enrollment = {
            ...updateData.enrollments,
            tuitionAndFees: {
              ...updateData.enrollments?.tuitionAndFees,
              tuition: {
                ...updateData.enrollments?.tuitionAndFees?.tuition,
                detailedTuition: {
                  ...updateData.enrollments?.tuitionAndFees?.tuition?.detailedTuition,
                  subsidized: data.subsidy?.value === 'yes',
                },
              },
            },
          };

          dispatch(organizationUpdateStudent(currentOrganization.id, updateData));
          if (!isSubmitting) {
            onClose();
            showToast(ToastTypeEnum.Success, t('apps.kinderConnect.student.success'));
          }
        } catch (error: any) {
          showToast(ToastTypeEnum.Error, t('apps.kinderConnect.student.error'));
          console.error('Unable to Update:', error.message);
        }
      } else {
        console.error('No student id found to update.');
      }
    }
  };

  const renderFoodProgramDropdown = () => {
    const options: Option[] = foodProgramTierOptions.map((option) => ({
      label: t(option.label),
      value: option.value.toString(),
    }));

    return (
      <Controller
        name="foodProgramTierObj"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            data-testid="food-program-tier"
            id="foodProgramTier"
            label={t('food.tierDropdownLabel')}
            placeholder={t('food.tierDropdownPlaceholder')}
            options={options}
            extraClasses="z-auto"
          />
        )}
      />
    );
  };

  return (
    <div className="text-left">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={isSubmitting ? 'animate-pulse' : ''}
        data-testid="student-programs-form"
      >
        <div className="my-4 space-y-3">
          {foodProgramFlag && (!showPrograms || showPrograms === 'foodProgram') && (
            <div className="w-full max-w-72">{renderFoodProgramDropdown()}</div>
          )}

          <div className="w-full max-w-32">
            <Controller
              name="subsidy"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  data-testid="subsidy"
                  id="subsidy"
                  label={t('common.revenueSubsidy')}
                  placeholder={t('common.revenueSubsidy')}
                  options={subsidyOptions}
                  extraClasses="z-auto"
                />
              )}
            />
          </div>
        </div>

        {currentOrganization?.isKinderSystemsEnabled && (!showPrograms || showPrograms === 'kinderConnect') && (
          <div className="mb-4 flex w-full">
            <div className="w-2/3">
              <Controller
                name="kinderConnectId"
                control={control}
                render={({ field }) => (
                  <>
                    <Input
                      {...field}
                      id="kinderConnectId"
                      inputType="number"
                      label={t('KinderConnect ID')}
                      placeholder={t('KinderConnect ID')}
                      data-testid="kinder-connect-id"
                    />
                  </>
                )}
              />
            </div>
          </div>
        )}

        <div className="flex space-x-4">
          <Button
            primary
            label={t('Save')}
            disabled={isSubmitting}
            loading={isSubmitting}
            data-testid="programs-edit-form-save-btn"
            type="submit"
          />

          <Button
            label={t('common.cancel')}
            onClick={(e) => {
              e.preventDefault();
              onClose();
            }}
            data-testid="programs-edit-form-cancel-btn"
          />
        </div>
      </form>
    </div>
  );
}
