import { Spinner, WidgetSizeEnum } from '@wonderschool/common-base-ui';
import { useStudentWithAddressQuery } from '@wonderschool/common-food-program-data-access';
import { useMemo } from 'react';
import { connect } from 'react-redux';
import { StudentAddressForm } from './StudentAddressForm';
import { StudentPersonalInformationProviderApi } from './StudentPersonalInformationProviderApi';

interface StudentPersonalInformationApiLoaderProps {
  selectedStudent: {
    id: string;
    marketplaceId?: number;
  };
}

/**
 * This is a temporary wrapper component that loads from Provider API the right student using the passed in
 * Firebase ID. It can be deleted when StudentsTable loads students from the same source.
 */
function StudentPersonalInformationApiLoader({ selectedStudent }: StudentPersonalInformationApiLoaderProps) {
  const { data, loading: isStudentQueryLoading } = useStudentWithAddressQuery({
    variables: {
      args: {
        firebaseId: selectedStudent.id,
      },
    },
  });

  const safeStudent = useMemo(
    () =>
      !!data && data.student
        ? { ...data.student, childId: data.student.childId || selectedStudent.marketplaceId }
        : undefined,
    [data, selectedStudent.marketplaceId]
  );

  if (!selectedStudent.id) return null;

  if (isStudentQueryLoading) {
    return <Spinner size={WidgetSizeEnum.X_LARGE} spinnerColor="purple" className="m-10" />;
  }

  if (!safeStudent) {
    return null;
  }

  return (
    <>
      <StudentPersonalInformationProviderApi student={safeStudent} />
      <StudentAddressForm student={safeStudent} address={safeStudent.address || null} />
    </>
  );
}

const mapStateToProps = (state) => ({
  selectedStudent: state.students.selectedStudent,
});

// We are temporarily connecting with Redux here. The trick is that this file can be thrown away
// when StudentsTable loads all data from Postgres and passes the loaded student directly to the component
// holding the form.
export const StudentPersonalInformationForm = connect(mapStateToProps)(StudentPersonalInformationApiLoader);
