import {
  ClockIcon,
  CrossCircleIcon,
  showToast,
  SuccessIcon,
  ToastTypeEnum,
  Tooltip,
} from '@wonderschool/common-base-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePrimaryLocation } from '../../../hooks/useLocations';
import { useOrganization } from '../../../hooks/useOrganizations';
import useRooms from '../../../hooks/useRooms';
import { ExportToolbar } from '../../components';

import {
  sortCollection,
  SortDirectionEnum,
  SortDirectionType,
  TABLE_CELL_CLASSES,
  WsTableHeader,
} from '../../../common';
import { TransferDetailsDialog } from '../../../Pages/AppStore/KinderConnect/TranferDetailsDialog';
import { PDFModal } from '../../components/PDFModal';
import { formatAttendanceForKinderSystems, useDailyAttendance } from '../attendanceHooks';
import { generateDailyAttendanceCSV, generateDailyAttendancePDF } from '../attendanceUtils';
import {
  AttendanceTableProps,
  AttendanceType,
  DailyAttendanceReportType,
  KINDERSYSTEMS_REPORTS_STATUS,
} from '../types';
import { CheckInBadge, CheckOutBadge } from './AttendanceBadges';
import { AttendanceDetailsModal } from './AttendanceDetailsModal';
import { SignatureModal } from './SignatureModal';

import { firestore } from '../../../api/firebase/firebase';
import { uploadAttendanceToKinderSystems } from '../../../api/firebase/kinderSystems';
import { logInfo } from '../../../rollbar';

const DailyAttendanceTable: React.FC<AttendanceTableProps> = ({
  filters,
  activities = [],
  kinderSystemsReport = false,
}) => {
  const { t } = useTranslation();
  const organization = useOrganization();
  const location = usePrimaryLocation();
  const rooms = useRooms();

  const dailyAttendance = useDailyAttendance(activities);

  const [attendanceSorted, setAttendanceSorted] = useState<AttendanceType[]>(dailyAttendance);
  const [selectedAttendance, setSelectedAttendance] = useState<AttendanceType | undefined>();
  const [isPDFModalOpen, setIsPDFModalOpen] = useState(false);
  const [sortDirection, setSortDirection] = useState<SortDirectionType>({
    name: '',
    direction: SortDirectionEnum.NONE,
  });
  const [isTransferDetailsDialogOpen, setIsTransferDetailsDialogOpen] = useState(false);
  const [isAttendanceDetailsModalOpen, setIsAttendanceDetailsModalOpen] = useState(false);
  const [isTransferringAttendance, setIsTransferringAttendance] = useState(false);

  const onSort = useCallback((sortDirection: SortDirectionType) => {
    setSortDirection(sortDirection);
  }, []);

  const attendanceReport = useMemo<DailyAttendanceReportType>(() => {
    return {
      startDate: filters.startDate,
      endDate: filters.endDate,
      organization,
      location,
      room: filters.roomId ? rooms.find((room) => room.id === filters.roomId) : null,
      items: attendanceSorted,
    };
  }, [attendanceSorted, filters, location, organization, rooms]);

  const onExportPDF = useCallback(() => {
    setIsPDFModalOpen(true);
  }, []);

  const onExportCSV = useCallback(() => {
    generateDailyAttendanceCSV(attendanceReport);
  }, [attendanceReport]);

  const onClickAttendance = useCallback((attendance: AttendanceType) => {
    setIsAttendanceDetailsModalOpen(true);
    setSelectedAttendance(attendance);
  }, []);
  const onCloseAttendanceDetailsModal = useCallback(() => {
    setIsAttendanceDetailsModalOpen(false);
    setSelectedAttendance(undefined);
  }, []);

  const onClosePDFModal = useCallback(() => {
    setIsPDFModalOpen(false);
  }, []);

  useEffect(() => {
    if (!dailyAttendance.length) setAttendanceSorted(dailyAttendance);
    else setAttendanceSorted(sortCollection<AttendanceType>(dailyAttendance, sortDirection));
  }, [dailyAttendance, sortDirection]);

  const handleDetailsClick = useCallback((attendance: AttendanceType) => {
    setSelectedAttendance(attendance);
    setIsTransferDetailsDialogOpen(true);
  }, []);

  const onCloseTransferDetailsModal = useCallback(() => {
    setIsTransferDetailsDialogOpen(false);
    setSelectedAttendance(undefined);
  }, []);

  const submitAttendanceToKinderSystems = useCallback(
    async (attendance) => {
      try {
        const formattedAttendance = (await formatAttendanceForKinderSystems(attendance, organization.id)) as any;
        if (formattedAttendance) {
          if (typeof formattedAttendance === 'string') {
            showToast(ToastTypeEnum.Error, formattedAttendance);
          } else {
            if (attendance.isAbsent || (!attendance.isAbsent && formattedAttendance.activities.length >= 2)) {
              const response = await uploadAttendanceToKinderSystems({
                attendanceList: [formattedAttendance],
                organizationId: organization.id,
                locationId: location.id,
              });
              logInfo('Attendance transferred to KinderSystems', {
                formattedAttendance,
                response,
              });
              showToast(ToastTypeEnum.Success, t('apps.kinderConnect.transfer.successMsg'));
              attendance.kinderSystemsTransferred.status = KINDERSYSTEMS_REPORTS_STATUS.TRANSFERRED;
              attendance.kinderSystemsTransferred.transferredAt = firestore.Timestamp.now();
              if (!attendance.kinderSystemsTransferred.transferHistory)
                attendance.kinderSystemsTransferred.transferHistory = [];
              attendance.kinderSystemsTransferred.transferHistory.push({
                status: KINDERSYSTEMS_REPORTS_STATUS.TRANSFERRED,
                transferredAt: firestore.Timestamp.now(),
              });
            } else {
              showToast(ToastTypeEnum.Error, t('apps.kinderConnect.transfer.missingMsg'));
            }
            setIsTransferringAttendance(false);
          }
        }
      } catch (error) {
        showToast(ToastTypeEnum.Error, t('apps.kinderConnect.transfer.errorMsg'));
        attendance.kinderSystemsTransferred.status = KINDERSYSTEMS_REPORTS_STATUS.ERROR;
        setIsTransferringAttendance(false);
      }
    },
    [organization.id, location.id, t]
  );

  const handleTransferAttendance = useCallback(() => {
    setIsTransferringAttendance(true);
    submitAttendanceToKinderSystems(selectedAttendance);
    setIsTransferDetailsDialogOpen(false);
  }, [selectedAttendance, submitAttendanceToKinderSystems]);

  return (
    <>
      <ExportToolbar onExportPDF={onExportPDF} onExportCSV={onExportCSV} disabled={!attendanceSorted?.length} />
      <div className="mt-4 overflow-x-scroll sm:overflow-x-hidden">
        <table className="min-h-full min-w-full" data-testid="daily-attendance-table">
          <thead className="bg-gray-100" data-testid="daily-attendance-thead">
            <tr className="border-y-2 border-gray-300">
              <WsTableHeader label={t('Name')} sortKey="studentName" onSort={onSort} sortDirection={sortDirection} />
              <WsTableHeader label={t('Room')} sortKey="roomName" onSort={onSort} sortDirection={sortDirection} />
              <WsTableHeader label={t('Date')} sortKey="checkInDate" onSort={onSort} sortDirection={sortDirection} />
              <WsTableHeader
                label={t('Check In')}
                sortKey="checkInTimeSort"
                onSort={onSort}
                sortDirection={sortDirection}
              />
              <WsTableHeader label={t('Signee')} sortKey="checkInName" onSort={onSort} sortDirection={sortDirection} />
              <WsTableHeader
                label={t('Check Out')}
                sortKey="checkOutTimeSort"
                onSort={onSort}
                sortDirection={sortDirection}
              />
              <WsTableHeader label={t('Signee')} sortKey="checkOutName" onSort={onSort} sortDirection={sortDirection} />
              {kinderSystemsReport && <WsTableHeader label={t('kinderConnect.status')} />}
              {kinderSystemsReport && <WsTableHeader label={t('Details')} />}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-300">
            {attendanceSorted.map((attendance) => {
              const {
                activityId,
                checkInDate,
                checkInTime,
                checkInName,
                checkInSignatureUri,
                checkOutDate,
                checkOutName,
                checkOutTime,
                checkOutSignatureUri,
                isAbsent,
                roomName,
                studentName,
                healthCheck,
                kinderSystemsTransferred,
              } = attendance;

              return (
                <tr key={activityId} className="cursor-pointer" onClick={() => onClickAttendance(attendance)}>
                  <td className={TABLE_CELL_CLASSES} data-testid={`activity-student-name-${activityId}`}>
                    {studentName || '-'}
                  </td>
                  <td className={TABLE_CELL_CLASSES} data-testid={`activity-room-name-${activityId}`}>
                    {roomName || '-'}
                  </td>
                  <td className={TABLE_CELL_CLASSES} data-testid={`activity-checkin-date-${activityId}`}>
                    {checkInDate ? checkInDate : checkOutDate || '-'}
                  </td>
                  <td className={TABLE_CELL_CLASSES} data-testid={`activity-checkin-time-${activityId}`}>
                    <CheckInBadge time={checkInTime} isAbsent={isAbsent} emptyText="-" healthCheck={healthCheck} />
                  </td>
                  <td className={TABLE_CELL_CLASSES} data-testid={`activity-checkin-name-${activityId}`}>
                    <SignatureModal signatureUri={checkInSignatureUri} name={checkInName} />
                  </td>
                  <td className={TABLE_CELL_CLASSES} data-testid={`activity-checkout-time-${activityId}`}>
                    <CheckOutBadge time={checkOutTime} isAbsent={isAbsent} emptyText="-" />
                  </td>
                  <td className={TABLE_CELL_CLASSES} data-testid={`activity-checkout-name-${activityId}`}>
                    <SignatureModal signatureUri={checkOutSignatureUri} name={checkOutName} />
                  </td>
                  {kinderSystemsReport && (
                    <td className={TABLE_CELL_CLASSES}>
                      {kinderSystemsTransferred?.status ? (
                        <Tooltip action="hover" content={kinderSystemsTransferred?.status || t('Out of Sync')}>
                          {kinderSystemsTransferred.status === KINDERSYSTEMS_REPORTS_STATUS.TRANSFERRED && (
                            <span
                              className="flex w-32 flex-row rounded-full bg-green-200 px-2 py-2 text-sm"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDetailsClick(attendance);
                              }}
                            >
                              <SuccessIcon className="mr-1 h-5 w-5 text-green-700" />
                              {t('Transferred')}
                            </span>
                          )}
                          {kinderSystemsTransferred.status === KINDERSYSTEMS_REPORTS_STATUS.ERROR && (
                            <span
                              className="flex w-32 flex-row rounded-full bg-red-200 px-2 py-2 text-sm"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDetailsClick(attendance);
                              }}
                            >
                              <CrossCircleIcon className="mr-1 h-5 w-5 text-red-700" />
                              {t('Error')}
                            </span>
                          )}
                          {kinderSystemsTransferred.status !== KINDERSYSTEMS_REPORTS_STATUS.TRANSFERRED &&
                            kinderSystemsTransferred.status !== KINDERSYSTEMS_REPORTS_STATUS.ERROR && (
                              <span
                                className="flex w-32 flex-row rounded-full bg-yellow-200 px-2 py-2 text-sm"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDetailsClick(attendance);
                                }}
                              >
                                <ClockIcon className="mr-1 h-5 w-5 text-yellow-700" />
                                {t('Out of Sync')}
                              </span>
                            )}
                        </Tooltip>
                      ) : (
                        <Tooltip action="hover" content={kinderSystemsTransferred?.status || t('Out of Sync')}>
                          <span
                            className="flex w-32 flex-row rounded-full bg-yellow-200 px-2 py-2 text-sm"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDetailsClick(attendance);
                            }}
                          >
                            <ClockIcon className="mr-1 h-5 w-5 text-yellow-700" />
                            {t('Out of Sync')}
                          </span>
                        </Tooltip>
                      )}
                    </td>
                  )}
                  {kinderSystemsReport && (
                    <td className={TABLE_CELL_CLASSES}>
                      <a
                        className="text-blue-600"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDetailsClick(attendance);
                        }}
                      >
                        {t('Details')}
                      </a>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
        {isAttendanceDetailsModalOpen && (
          <AttendanceDetailsModal attendance={selectedAttendance} onClose={onCloseAttendanceDetailsModal} />
        )}
        <PDFModal
          isOpen={isPDFModalOpen}
          report={attendanceReport}
          pdfGenerator={generateDailyAttendancePDF as any}
          onClose={onClosePDFModal}
        />
        {isTransferDetailsDialogOpen && (
          <TransferDetailsDialog
            isOpen={isTransferDetailsDialogOpen}
            setIsOpen={setIsTransferDetailsDialogOpen}
            onClose={onCloseTransferDetailsModal}
            isTransferringAttendance={isTransferringAttendance}
            kinderSystemsData={selectedAttendance?.kinderSystemsTransferred}
            handleTransferAttendance={() => handleTransferAttendance()}
          />
        )}
      </div>
    </>
  );
};

export default DailyAttendanceTable;
