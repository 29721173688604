import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Import actions
import { roomSelected } from '../../redux/actions/roomActions';

// Import Components
import { AgeType, Room } from '@wonderschool/common-base-types';
import {
  Badge,
  ColorPaletteEnum,
  ColumnConfig,
  DataTable,
  MainContentLayout,
  SlideOver,
} from '@wonderschool/common-base-ui';
import { userHasPermission } from '../../api/firebase/account';
import { RootReducerState } from '../../redux/reducers/rootReducer';
import RoomForm from '../Forms/RoomForm';

export function RoomsList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedRoom = useSelector((state: RootReducerState) => state.rooms.selectedRoom);
  const rooms = useSelector((state: RootReducerState) => state.rooms);
  const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);
  const canAddRoom = userHasPermission('can_create_room');

  const title = useMemo(() => {
    if (rooms?.list) {
      return `${t('Rooms')} (${rooms.list.length})`;
    }
    return t('Rooms');
  }, [rooms?.list, t]);

  const tableColumns: ColumnConfig<Room>[] = [
    {
      label: t('Name'),
      fieldName: 'name',
    },
    {
      label: t('Age Range'),
      fieldName: 'ageRange',
      renderCell: (room: Room) => {
        return <div>{buildAgeRangeStr(room.ageRange ?? {}, t)}</div>;
      },
    },
    {
      label: t('Enabled'),
      fieldName: 'enabled',
      renderCell: (room: Room) => {
        const label = room.enabled ? t('Enabled') : t('Disabled');
        const color = room.enabled ? ColorPaletteEnum.GREEN : ColorPaletteEnum.RED;
        return <Badge label={label} color={color} />;
      },
    },
  ];

  const handleClickAddRoom = () => {
    dispatch(roomSelected());
    setIsSlideOverOpen(true);
  };

  const handleClickRow = (room: Room) => {
    dispatch(roomSelected(room));
    setIsSlideOverOpen(true);
  };

  const handleSlideoverCloseComplete = () => {
    dispatch(roomSelected());
  };

  const handleSubmit = (_room: Room) => {
    setIsSlideOverOpen(false);
  };

  return (
    <MainContentLayout
      title={title}
      primaryAction={
        canAddRoom
          ? {
              label: t('Add new room'),
              'data-testid': 'create-room-card-with-permission',
              onClick: handleClickAddRoom,
            }
          : undefined
      }
    >
      <DataTable data={rooms?.list} columns={tableColumns} onClickRow={handleClickRow} />
      <SlideOver
        title={selectedRoom ? t('Edit Room') : t('Add A Room')}
        isOpen={isSlideOverOpen}
        onClose={() => setIsSlideOverOpen(false)}
        onCloseComplete={handleSlideoverCloseComplete}
      >
        <div className="flex min-w-72">
          <RoomForm onSubmit={handleSubmit} onCancel={() => setIsSlideOverOpen(false)} />
        </div>
      </SlideOver>
    </MainContentLayout>
  );
}

interface AgeRangeProps {
  from: AgeType;
  to: AgeType;
}

const buildAgeRangeStr = ({ from, to }: AgeRangeProps, t: any) => {
  // resolve legacy data ---------------
  if (!from || !to) {
    return null;
  }
  if (typeof from === 'string' && typeof to === 'string') {
    if (from === to) {
      return t(from);
    }
    return `${t(from)} - ${t(to)}`;
  }

  // display none
  if (from.months === 0 && from.years === 0 && to.months === 0 && to.years === 0) {
    return null;
  }

  // returns 1 :year, 2 :years or 12+ :years
  const formatYears = (years) => {
    if (years === 0) {
      return '';
    }
    return `${years < 13 ? years : '12+'} ${years === 1 ? ':year' : ':years'}`;
  };

  // Returns 1 :month or 2 :months
  const formatMonths = (months) => {
    if (months === 0) {
      return '';
    }
    return `${months} ${months === 1 ? ':month' : ':months'}`;
  };

  // replace :year, :years, :month, :months with translation
  const replaceDateParts = (str, abbreviate) => {
    return str
      .replace(/:years/g, t(abbreviate ? 'Yrs' : 'Years'))
      .replace(/:year/g, t(abbreviate ? 'Yrs' : 'Year'))
      .replace(/:months/g, t(abbreviate ? 'Mos' : 'Months'))
      .replace(/:month/g, t(abbreviate ? 'Mos' : 'Month'));
  };

  let fromStr = `${formatYears(from.years)} ${formatMonths(from.months)}`.trim();
  if (fromStr === '') {
    fromStr = '0';
  }

  const toStr = `${formatYears(to.years)} ${formatMonths(to.months)}`.trim();

  if (fromStr === toStr) {
    return replaceDateParts(fromStr, false);
  }

  // If both year or month are 0, the fromStr should only be a number and doesn't need abbreviation
  let abbreviate = true;
  if ((from.years === 0 && to.years === 0) || (from.months === 0 && to.months === 0)) {
    fromStr = (from.years || from.months).toString();
    abbreviate = false;
  }

  // join from and to if they are not empty
  const dateParts = [fromStr, toStr].filter((str) => str !== '');
  return dateParts.map((str) => replaceDateParts(str, abbreviate && dateParts.length > 1)).join(' - ');
};
