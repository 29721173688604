import firebase from 'firebase/compat/app';

export type FirestoreDocumentObject<T extends firebase.firestore.DocumentData> = T & { id: string };

export type FirestoreDocumentObjectTree<T extends firebase.firestore.DocumentData> = Record<
  string,
  FirestoreDocumentObject<T>
>;

/**
 * Builds a tree of objects from an array of firestore document snapshots.
 * @param docs - The array of firestore document snapshots.
 * @returns The tree of objects built from the document snapshots.
 */
export function buildObjectsTree(
  docs: firebase.firestore.DocumentSnapshot[]
): Record<string, FirestoreDocumentObject<firebase.firestore.DocumentData>> {
  const objects = {};
  docs.forEach((doc) => (objects[doc.id] = { id: doc.id, ...doc.data() }));
  return objects;
}

/**
 * Builds an object from a firestore document snapshot.
 * @param doc - The firestore document snapshot.
 * @returns The object built from the document snapshot.
 */
export function buildObject<T extends firebase.firestore.DocumentData>(
  doc: firebase.firestore.DocumentSnapshot
): FirestoreDocumentObject<T> | null {
  const data = doc.data();
  if (!data) return null;
  return { id: doc.id, ...data } as FirestoreDocumentObject<T>;
}
