import { capitalizeFirstLetter } from '@wonderschool/ccms-ui';
import { EthnicityEnumTextMap, GenderEnumTextMap, RaceEnumTextMap } from '@wonderschool/common-base-types';
import { EthnicityEnum, GenderEnum, RaceEnum } from '@wonderschool/common-food-program-data-access';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StudentFormType, StudentPersonalInformationProps } from '../studentForm.type';

export function useDefaultStudentValues(student: StudentPersonalInformationProps['student']) {
  const { t } = useTranslation();
  const translatedRaceOptions = useMemo(
    // lowercasing is a temporary workaround for https://linear.app/wonderschool/issue/CORE-446
    () => Object.values(RaceEnum).map((race) => ({ label: t(RaceEnumTextMap[race.toLowerCase()]), value: race })),
    [t]
  );

  const translatedEthnicityOptions = useMemo(
    () =>
      Object.values(EthnicityEnum).map((ethnicity) => ({
        // lowercasing is a temporary workaround for https://linear.app/wonderschool/issue/CORE-446
        label: t(EthnicityEnumTextMap[ethnicity.toLowerCase()]),
        value: ethnicity,
      })),
    [t]
  );

  const translatedGenderOptions = useMemo(
    () =>
      Object.values(GenderEnum).map((gender) => ({
        // lowercasing is a temporary workaround for https://linear.app/wonderschool/issue/CORE-446
        label: capitalizeFirstLetter(t(GenderEnumTextMap[gender.toLowerCase()])),
        value: gender,
      })),
    [t]
  );

  const memoizedValues: StudentFormType = useMemo(() => {
    const {
      firstName,
      middleName,
      lastName,
      nickname,
      birthDate,
      gender,
      allergies = '',
      medications = '',
      doctorName = '',
      doctorPhone = '',
      notes = '',
      race,
      ethnicity,
      photosAllowed,
    } = student;

    const transformedGender = translatedGenderOptions.find(
      (genderOption) => genderOption.value.toLowerCase() === gender?.toLowerCase()
    );
    const transformedRace = translatedRaceOptions.find(
      (raceOption) => raceOption.value.toLowerCase() === race?.toLowerCase()
    );
    const transformedEthnicity = translatedEthnicityOptions.find(
      (ethnicityOption) => ethnicityOption.value.toLowerCase() === ethnicity?.toLowerCase()
    );

    return {
      firstName,
      middleName,
      lastName,
      nickname,
      birthDate: birthDate ? dayjs(birthDate).toISOString().substring(0, 10) : null,
      gender: transformedGender,
      allergies: allergies || '',
      medications: medications || '',
      doctorName: doctorName || '',
      doctorPhone: doctorPhone || '',
      notes: notes || '',
      race: transformedRace,
      ethnicity: transformedEthnicity,
      photosAllowed: !!photosAllowed,
    };
  }, [student, translatedEthnicityOptions, translatedGenderOptions, translatedRaceOptions]);

  return {
    translatedEthnicityOptions,
    translatedRaceOptions,
    translatedGenderOptions,
    memoizedValues,
  };
}
