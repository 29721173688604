import { contactsOnListen } from './contactsAPI';

const ORGANIZATION_CONTACTS_FETCHED = 'ORGANIZATION_CONTACTS_FETCHED';

export const organizationContactsFetched = (contacts) => ({
  type: ORGANIZATION_CONTACTS_FETCHED,
  contacts,
});

export const startOrganizationContactsListener = (organizationId, conditions) => (dispatch) => {
  return contactsOnListen(
    { organizationId, conditions },
    (contacts) => {
      if (contacts) dispatch(organizationContactsFetched(contacts));
    },
    (error) => console.log(error.message)
  );
};

export const startOrganizationStaffListener = (organizationId) => (dispatch) => {
  const conditions = [{ field: 'type', operator: '==', value: 'staff' }];
  return contactsOnListen(
    { organizationId, conditions },
    (contacts) => {
      if (contacts) dispatch(organizationContactsFetched(contacts));
    },
    (error) => console.log(error.message)
  );
};

export type ContactsReducerState = {
  count: number;
  list: {
    id: string;
  }[];
};

const initialState: ContactsReducerState = {
  count: 0,
  list: [],
};

export const contactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORGANIZATION_CONTACTS_FETCHED:
      return {
        count: action.contacts.count,
        list: action.contacts.list,
      };
    default:
      return state;
  }
};
