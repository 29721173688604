import { StudentType } from '@wonderschool/common-base-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useOrganization } from '../../../hooks/useOrganizations';

interface StudentProgramsProviderApiProps {
  student: StudentType;
}

/**
 * Replaces "StudentPrograms" component which defined its own margins and padding
 */
export function StudentProgramsProviderApi({ student }: StudentProgramsProviderApiProps) {
  const { t } = useTranslation();
  const { foodProgramFlag } = useFlags();
  const { isKinderSystemsEnabled } = useOrganization();

  if (!student?.id) return null;

  const isStudentSubsidized = (enrollment) => {
    return enrollment?.tuitionAndFees?.tuition?.detailedTuition?.subsidized;
  };

  return (
    <div className="flex flex-col gap-2">
      {isKinderSystemsEnabled && (
        <div className="mb-6 grid grid-cols-3 gap-4">
          <div>{t('KinderConnect ID')}</div>
          <div className="col-span-2 ml-5">
            <strong>{student?.kinderSystems?.kinderConnectId ? student.kinderSystems.kinderConnectId : '-'}</strong>
          </div>
        </div>
      )}
      {foodProgramFlag && (
        <div className="grid grid-cols-3 gap-4">
          <div>{t('food.tierLabel')}</div>
          <div className="col-span-2 ml-5">
            <strong>
              {student?.foodProgramTier === null || student?.foodProgramTier === undefined
                ? t('food.tierDropdown.NotApplicable')
                : t(
                    student.foodProgramTier
                      ? `food.tierDropdown.Tier${student.foodProgramTier}`
                      : 'food.tierDropdown.NoTier'
                  )}
            </strong>
          </div>
        </div>
      )}
      <div className="grid grid-cols-3 gap-4">
        <div>{t('common.revenueSubsidy')}</div>
        <div className="col-span-2 ml-5">
          <strong>{isStudentSubsidized(student?.enrollment) ? t('common.yes') : t('common.no')}</strong>
        </div>
      </div>
    </div>
  );
}
