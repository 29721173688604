import { functions } from './firebase';

export interface ProcessingFees {
  cardFeePercentage: number;
  cardFixedFee: number;
  achFeePercentage: number;
  achMaxFee: number;
}

export const fetchProcessingFeeSettings = async () => {
  const { data } = await functions().httpsCallable('callables-processingFees-getProcessingFeeSettings')();
  return data;
};
