// A flat list of routes in alphabetical order. The enum values are also
// used as the menu item names in the UI. Not sure if that's a good idea or not
export enum RouteNameEnum {
  ADMIN_DOCUMENTS = 'admin.wonderschoolDocuments',
  AGING_REPORTS = 'Aging Reports',
  APPS = 'Apps',
  AREA_51 = 'Area 51',
  ATTENDANCE = 'Attendance',
  AUTH_ERROR = 'Auth Error',
  AUTH_RETURN = 'Auth Return',
  AUTH_TOKEN = 'Auth Token',
  BILLING_SETTINGS = 'Settings',
  CALENDAR = 'Calendar',
  CONTACTS = 'familyAndContactsLabel',
  DEVICES = 'Devices',
  DOCUMENTS = 'Documents',
  ENROLLED_FAMILIES = 'Enrolled Families',
  ENROLLMENTS_ADMIN = 'Enrollments Admin',
  EVENTS = 'Events',
  FOOD_PROGRAM_CLAIMS = 'food.claims.list',
  FOOD_PROGRAM_CLAIM_CREATE = 'food.claims.create',
  FOOD_PROGRAM_CLAIM_UPDATE = 'food.claims.update',
  FOOD_PROGRAM_MEALS = 'food.meals.list',
  FOOD_PROGRAM_MEAL_CREATE = 'food.meals.create',
  FOOD_PROGRAM_MEAL_UPDATE = 'food.meals.update',
  FOOD_PROGRAM_MEAL_RECORD_CREATE = 'food.meals.record.create',
  FOOD_PROGRAM_MENU_PLANNING = 'food.menu.planning',
  FOOD_PROGRAM_MENU_PLANNING_EDIT = 'food.menu.planning.edit',
  FOOD_PROGRAM_REPORTS = 'food.reports',
  FORMS_BUILDER = 'Forms Builder',
  HELP_CENTER = 'Help Center',
  HOME = 'Home',
  INTERESTED_FAMILIES = 'Interested Families',
  INVOICE_EDIT_CONFIRM = 'Invoice Edit Confirm',
  INVOICE_EDIT_DETAILS = 'Invoice Edit Details',
  INVOICE_EDIT_STUDENTS = 'Invoice Edit Students',
  INVOICES = 'Invoices',
  LEARNING_BEYOND_PAPER = 'Learning Beyond Paper',
  KINDER_CONNECT = 'Kinder Connect',
  LOCATIONS = 'Location Details',
  LOCATION_EDIT = 'Location Edit',
  MARKETING = 'Marketing',
  MESSAGES = 'Prospective Families',
  MESSAGING = 'Messages',
  MIGRATION_REPORT = 'Migration Report',
  NETWORKS = 'Networks',
  ONBOARDING = 'Onboarding',
  PARENT_HANDBOOK = 'Parent Handbook',
  PLAN_DETAILS = 'Plan Details',
  PROFILE = 'Profile',
  FINANCIAL_SUMMARY = 'financialSummary.title',
  PROVIDERS = 'Providers',
  RECURRING_PLANS = 'Recurring Plans',
  RECURRING_PLAN_EDIT_DETAILS = 'Recurring Plan Edit Details',
  ROOMS = 'Rooms',
  SHARED_DEVICES = 'Shared Devices',
  STAFF = 'Staff',
  STAFF_DETAILS = 'Staff Details',
  STAFF_TIMESHEET = 'timecards.staffTimesheet',
  STUDENT_ADD = 'Add Student',
  STUDENT_ADD_CHILD = 'Add Child',
  STUDENT_ENROLLMENT = 'Enrollment',
  STUDENT_ENROLLMENT_SUMMARY = 'Enrollment Summary',
  STUDENT_ENROLLMENT_INVOICE = 'Enrollment Invoice',
  STUDENTS = 'Students',
  STUDENTS_IMPORT = 'Import Students',
  STUDENTS_FILTER = 'Students Filter',
  SYSTEM_NOTIFICATIONS = 'System Notifications',
  TIME_SPACE_CALCULATOR = 'Time Space Percentage Calculator',
  TOURS = 'Tours',
  TOUR_REQUESTS = 'Tour Requests',
  TRANSACTIONS = 'Transactions',
  UPDATE_WEBSITE = 'Update Website',
  USERS = 'Users',
  VIEW_WEBSITE = 'View Website',
  WELCOME = 'Welcome',
  ZZZ = 'ZZZ', //For testing route not found
  DEBUG_PAGE = 'Debug Page',
}

// IMPORTANT: These names cannot change, as they are used as feature flags in the DB.
export enum FeatureFlagEnum {
  DASHBOARD = 'Dashboard',
  DOCUMENTS = 'Documents',
  FINANCES = 'Finances',
  HELP_CENTER = 'Help/Resource Hub',
  MARKETPLACE = 'My Website',
  REPORTS = 'Reports',
  SETTINGS = 'Settings',
  STAFF = 'Staff',
  STUDENTS = 'Students',
  APP_STORE = 'Application Store',
}

// Type for the routes array defined in routes.ts
export type RouteType = {
  name: RouteNameEnum;
  path: string;
  headlessPath?: string;
  permission?: string;
  featureFlag?: FeatureFlagEnum;
  component?: React.ComponentType<any>;
  shouldOpenInNewTab?: boolean;
  shouldHide?: () => boolean;
  newFeatureFlag?: string;
};

export type RouteParamType = {
  name: string;
  value: string;
};

// Type for the useRoutes hook
export type UseRoutesReturnType = {
  allRoutes: RouteType[];
  allActiveRoutes: RouteType[];
  getActiveRoute: (routeToFind: RouteNameEnum) => RouteType | undefined;
  getActiveRouteByPath: (path: string) => RouteType | undefined;
  getActiveRoutes: (routesToFind: RouteNameEnum | RouteNameEnum[]) => RouteType[];
  getRoute: (routeToFind: RouteNameEnum) => RouteType | undefined;
  getRoutePath: (route: RouteType, params?: RouteParamType[]) => string;
  getRoutePathByName: (routeName: RouteNameEnum, params?: RouteParamType[]) => string | undefined;
  goBack: () => void;
  gotoRoute: (route: RouteType, params?: RouteParamType[]) => void;
  gotoRouteByName: (routeName: RouteNameEnum, params?: RouteParamType[]) => void;
  isRouteActive: (route: RouteType) => boolean;
};

// Type for the invoicePaths object returned by the usePaths hook
export type InvoicePathsType = {
  invoices: string;
  invoiceEditConfirm: string;
  invoiceEditDetails: string;
  invoiceEditStudents: string;
};

// Type for the invoicePlanPaths object returned by the usePaths hook
export type RecurringPlansPathsType = {
  recurringPlans: string;
  recurringPlanEditDetails: string;
};

// Type for the usePaths hook
export type UsePathsReturnType = {
  paths: any; // I don't know how to type a const object
  invoicePaths: InvoicePathsType;
  recurringPlansPaths: RecurringPlansPathsType;
};
